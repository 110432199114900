<template>
  <div>
    <!-- <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/> -->
    <v-card :elevation="isEmbedded ? 0 : 2" :class="isEmbedded ? 'ma-0' : ''">
      <v-card-title v-if="!isEmbedded" class="display-2 mb-4">Step Converter</v-card-title>
      <v-card-text v-if="!isEmbedded">
        <div class="float-right">
          <v-btn-toggle v-model="unit" mandatory class="multiline-btns">
            <v-btn v-for="val in [{type: 'METRIC', text: 'Km'}, {type: 'IMPERIAL', text: 'Miles'}]" :key="val.type" :value="val.type" small>
              {{val.text}}
            </v-btn>
          </v-btn-toggle>

        </div>
        Calculate and convert activities to their step equivalent.
      </v-card-text>
      <v-card-text>
        
        Please read our <a href="https://corporatefitness.helpscoutdocs.com/article/29-automatic-step-conversion" target="_blank">knowledge base article</a> on step conversion for additional background information.
      </v-card-text>
      <v-card-text :class="isEmbedded ? 'pa-0' : ''">
      <v-alert v-if="error || !valid" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
      <v-form ref="form" v-model="valid" lazy-validation v-if="activity">

          <v-card-title class="px-0 subtitle py-0">{{$t('profile.activities.entry.activity-details')}}</v-card-title>
          <v-card-text class="pa-0">      
            <v-btn-toggle v-model="activity.type">
              <v-btn v-for="type in siteData.activities.filter(x => x.core && x.type != 'DAILY_SUMMARY')" :key="type.type" :value="type.type" :title="$helpers.getActivityTypeLabel(type.type)">
                <v-icon :title="type.text" >{{$helpers.getActivityIcon(type.type)}}</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-2">{{ $helpers.getActivityTypeLabel(type.type) }}</span>
              </v-btn>
            </v-btn-toggle>
              <v-menu offset-y max-height="400">
                <template v-slot:activator="{ on, attrs }">
                  <div class="v-item-group theme--light v-btn-toggle">
                  <v-btn v-bind="attrs" v-on="on" :color="siteData.activities.some(x => x.core && x.type == activity.type)?'':'grey lighten-1'" title="Select Another Activity Type">
                    <v-icon title="More options" >fa-ellipsis-h</v-icon>
                    <span v-if="$vuetify.breakpoint.smAndUp" class="ml-2">{{$t('shared.more')}}</span>
                  </v-btn>
                  </div>
                </template>
                <v-list>
                  <v-list-item-group v-model="activity.type">
                  <v-list-item v-for="(item, idx) in siteData.activities.filter(x => !x.core)" :key="idx" :value="item.type">
                    <v-list-item-icon><v-icon v-text="$helpers.getActivityIcon(item.type)"></v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $helpers.getActivityTypeLabel(item.type) }}</v-list-item-title>
                  </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            {{ activity.type ? $helpers.getActivityTypeLabel(activity.type) : '' }}

            <DurationTextArea
              v-model="activity.time_s" 
              icon="fa-clock"
              class="mb-8 mt-8"
              :label="$t('profile.activities.entry.duration')" 
              required
              />

            <DistanceTextArea
              v-model="activity.dist" 
              icon="fa-ruler"
              :unit="unit"
              :label="$t('profile.activities.entry.distance-label', { unit: unitForDisplay, requirement: $t('shared.optional') } )" 
              class="mb-8"
              />
              
          </v-card-text>      

          <v-card-text class="px-0 pt-0">   
            <v-btn class="mt-8 mb-8" block color="primary" :loading="$store.getters.isLoading" @click="submit(false)">Calculate</v-btn>
          </v-card-text>   
      </v-form>
      </v-card-text>
      <v-card-text v-if="result">
        <h3>Calculated Steps</h3>
        <v-container class="mt-0">
          <v-row>
            <v-col v-if="result.steps_total && result.steps_total > 0" cols="12" sm="4" md="4">
              <v-card class="white--text" color="primary darken-3">
                <v-card-text class="white--text">{{$t('profile.activities.steps')}}</v-card-text>
                <v-card-title class="subtitle">{{ result.steps_total | steps }}</v-card-title>
              </v-card>
            </v-col>
            <v-col v-if="result.steps_min && result.steps_min > 0" cols="12" sm="4" md="4">
              <v-card class="white--text" color="primary darken-2">
                <v-card-text class="white--text">Steps per min</v-card-text>
                <v-card-title class="subtitle">{{ result.steps_min | steps }}</v-card-title>
              </v-card>
            </v-col>
            <v-col v-if="result.met_score && result.met_score > 0" cols="12" sm="4" md="4">
              <v-card class="white--text" color="primary darken-1">
                <v-card-text class="white--text">MET Score</v-card-text>
                <v-card-title class="subtitle">{{ result.met_score }}</v-card-title>
              </v-card>
            </v-col>
            <v-col v-if="result.pace && result.pace > 0" cols="12" sm="4" md="4">
              <v-card class="white--text" color="secondary darken-3">
                <v-card-text class="white--text">{{$t('profile.activities.pace')}}</v-card-text>
                <v-card-title class="subtitle">{{ result.pace | pace(unit, activity.type) }}</v-card-title>
              </v-card>
            </v-col>
            <v-col v-if="result.speed && result.speed > 0" cols="12" sm="4" md="4">
              <v-card class="white--text" color="secondary darken-2">
                <v-card-text class="white--text">{{$t('results.cols.speed')}}</v-card-text>
                <v-card-title class="subtitle">{{ result.speed | speed(unit, activity.type) }}</v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>        
      </v-card-text>
      
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import activityService from "@/services/activityService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ManualActivityEntry",
  components: {
    VueMarkdown,
    DistanceTextArea,
    DurationTextArea,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      activity: null,
      unit: 'METRIC',
      error: null,
      result: null,
      valid: true,
      siteData: siteData,
      infoMessage: null,
      requiredFieldRules: [
        v => !!v || 'Please enter this field',
      ],
      numberRules: [
        () => activityService.validateDistance(this.activity),//() => (this.activity.dist > 0 || this.activity.type === 'OTHER' || this.activity.type === 'YOGA' || this.activity.type === 'INDOOR_CARDIO' || this.activity.type === 'INDOOR_CYCLING') || "Please enter a positive number",
      ],
    };
  },
  async mounted() {
    this.activity = {
      start: DateTime.now().startOf('day').toISO(),
      type: 'RUNNING',
      dist: 0,
      time_s: 0,
    }
  },
  methods: {
    
    async submit(ignoreValidation) {
      if (this.activity.type == null) {
        this.error = 'Please select the type of activity.';
        return;
      }
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;
        try {
          //var validation = (await activityService.validate(this.activity)).data;
          this.result = (await activityService.calculateSteps(this.activity, this.$route.query.algo)).data;
          //console.log(this.response);
        }
        catch (ex) {
          this.error = 'Error calculating steps, please check all input.';
        }
      }
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    unitForDisplay() {
      return this.unit == 'METRIC' ? 'km' : 'mi';
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },

  },

};
</script>
<style lang="scss">
.v-input .v-messages {min-height: 0;;}
</style>

